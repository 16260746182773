import styled from 'styled-components'

import media from '../../common/MediaQueries'
import { Heading5, Body } from '../Typography/Typography'

const StyledLatestNewsContent = styled.div``

const StyledLatestNewsHead = styled.div`
  & + ${StyledLatestNewsContent} {
    margin-top: 2rem;

    @media ${media.phone} {
      margin-top: 3rem;
    }
  }
`

const StyledLatestNewsHeader = styled(Heading5)`
  text-align: center;
  margin-bottom: 1.5rem;

  & + ${StyledLatestNewsContent} {
    margin-top: 2rem;
  }

  @media ${media.phone} {
    & + ${StyledLatestNewsContent} {
      margin-top: 2.5rem;
    }
  }
`

const StyledLatestNewsDesc = styled(Body)`
  text-align: center;
  max-width: 40rem;
  margin: 0 auto;
`

export {
  StyledLatestNewsHead,
  StyledLatestNewsHeader,
  StyledLatestNewsDesc,
  StyledLatestNewsContent,
}
