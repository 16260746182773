import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledLatestNewsHead,
  StyledLatestNewsHeader,
  StyledLatestNewsDesc,
  StyledLatestNewsContent,
} from './styles'
import Container from '../Container'
import InternalLink from '../Buttons/InternalLink/InternalLink'
import NewsList from '../NewsList/NewsList'
import { fluidPropType } from '../../constants/proptypes'
import { URL_PATHS } from '../../constants/urlPaths'
import { BUTTON_TYPES } from '../../constants/buttonTypes'

const LatestNews = ({
  as,
  title,
  description,
  news,
  action,
  hasLoadMore,
  anim,
}) => (
  <>
    {(title || description) && (
      <StyledLatestNewsHead>
        {title && (
          <StyledLatestNewsHeader as="h3">{title}</StyledLatestNewsHeader>
        )}
        {description && (
          <StyledLatestNewsDesc>{description}</StyledLatestNewsDesc>
        )}
      </StyledLatestNewsHead>
    )}
    <StyledLatestNewsContent>
      <NewsList as={as} news={news} hasLoadMore={hasLoadMore} anim={anim} />
    </StyledLatestNewsContent>
    {action && (
      <Container mt={['2.5rem', '3rem']} textAlign="center" overflow="hidden">
        <InternalLink btnType={BUTTON_TYPES.secondary} to={URL_PATHS.NEWS}>
          View All News
        </InternalLink>
      </Container>
    )}
  </>
)

export default LatestNews

LatestNews.defaultProps = {
  action: false,
  as: 'h4',
  anim: false,
}

LatestNews.propTypes = {
  as: PropTypes.string,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.node.isRequired,
      to: PropTypes.string.isRequired,
      fluid: fluidPropType,
    }),
  ).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  anim: PropTypes.bool,
  action: PropTypes.bool,
}
