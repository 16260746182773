import styled from 'styled-components'
import Masonry from 'react-masonry-css'

import media from '../../common/MediaQueries'

const StyledNewsList = styled(Masonry)`
  display: flex;

  @media ${media.phone} {
    margin-left: -1.875rem;
  }
`

const StyledNewsListItem = styled.div`
  padding: 0 0.125rem;

  &:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  @media ${media.phone} {
    margin-left: 1.875rem;

    &:not(:last-child) {
      margin-bottom: 1.875rem;
    }
  }
`

export { StyledNewsList, StyledNewsListItem }
