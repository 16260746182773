import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _take from 'lodash/take'

import { StyledNewsList, StyledNewsListItem } from './styles'
import News from '../News/News'
import { fluidPropType } from '../../constants/proptypes'
import Button from '../Buttons/Button/Button'
import Container from '../Container'

const breakpointSettings = {
  default: 3,
  1050: 2,
  767: 1,
}

const PAGE_SIZE = 12

const NewsList = ({ as, news, hasLoadMore, anim }) => {
  const [page, setPage] = useState(1)

  const loadMore = useCallback(() => {
    const currentTop = window.pageYOffset

    setPage((prevPage) => prevPage + 1)
    // HACK: because it's scrolling down to button and we want to be where we clicked
    setTimeout(() => {
      window.scrollTo(0, currentTop)
    }, 0)
  }, [])

  const relatedNews = useMemo(
    () => (hasLoadMore ? _take(news, page * PAGE_SIZE) : news),
    [hasLoadMore, news, page],
  )

  return (
    <>
      <StyledNewsList breakpointCols={breakpointSettings} columnClassName="">
        {relatedNews.map(({ id, ...rest }) => (
          <StyledNewsListItem key={id}>
            <News as={as} key={id} {...rest} anim={anim} />
          </StyledNewsListItem>
        ))}
      </StyledNewsList>
      {hasLoadMore && relatedNews.length < news.length && (
        <Container
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="2rem"
        >
          <Button onClick={loadMore}>Load more</Button>
        </Container>
      )}
    </>
  )
}

export default NewsList

NewsList.defaultProps = {
  as: 'h4',
  anim: true,
}

NewsList.propTypes = {
  as: PropTypes.string,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.node.isRequired,
      to: PropTypes.string.isRequired,
      fluid: fluidPropType,
    }),
  ),
  anim: PropTypes.bool,
}
